<template>
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-md-3">
        <span>หวย</span>
      </div>
      <div class="col-md-9 text-primary">
        {{marketTitle}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <span>วันที่</span>
      </div>
      <div class="col-md-9 text-primary">
        {{roundDate}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <span>กลุ่มหวย</span>
      </div>
      <div class="col-md-9 text-primary">
        {{groupName}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <span>เวลาเปิดแทง</span>
      </div>
      <div class="col-md-9 text-primary">
        {{openDate}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <span>เวลาปิดรับ</span>
      </div>
      <div class="col-md-9 text-primary">
        {{closeDate}}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <span>สถานะ</span>
      </div>
      <div class="col-md-9">
        <RoundStatus v-if="data" :data="data" @cssStyle="setStatusStyle" class="badge badge-status font-weight-normal" :class="[cssStatusClass.bg, cssStatusClass.text]" style="font-size: 100%;" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <span>การรับโพย</span>
      </div>
      <div class="col-md-9" v-html="recordingStatus"></div>
    </div>
    <div v-if="status==='Open'" class="pt-5 d-flex justify-content-between">
      <button class="btn btn-sm btn-outline-primary mr-1" @click="editSetting"><i class="fas fa-cog"></i> ตั้งค่า</button>
      <button class="btn btn-sm btn-outline-danger mr-1" @click="cancelRound"><i class="fas fa-ban"></i> ยกเลิก/คืนเงิน</button>
    </div>

    <SettingRoundModal :data="data" :is-show="isShowSettingModal" @close="modalClosed" />
  </div>
</template>
<script>
import SettingRoundModal from './SettingRoundModal'
import RoundStatus from './RoundStatus'
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import cryptoRandomString from 'crypto-random-string'

export default {
  name: 'RoundSetting',
  components: {
    SettingRoundModal,
    RoundStatus
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isShowSettingModal: false,
      cssStatusClass: {
        bg: null,
        text: null,
        link: null,
        btn: null
      }
    }
  },
  computed: {
    groupName() {
      if(!this.data)
        return

      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === this.data.groupId
      })

      if(group) {
        return group?.groupTitle
      }else{
        return ''
      }
    },
    marketTitle() {
      if(!this.data)
        return

      const market = this.$store.state.markets.find((m)=>{
        return m._id === this.data?.marketId
      })

      return market?.marketTitle || this.data?.marketId
    },
    roundDate() {
      if(!this.data)
        return

      return this.data.roundDate.date
    },
    openDate() {
      if(!this.data)
        return

      return this.data.roundDate.open
    },
    closeDate() {
      if(!this.data)
        return

      return this.data.roundDate.close
    },
    status() {
      return this.data?.status
    },
    recordingStatus() {
      const type = this.data?.recording?.recordType || 'fast'
      const typeText = {
        'fast': '<span class="rounded px-1 badge-success">บันทึกเร็ว</span>',
        'queue': '<span class="rounded px-1 badge-warning">ต่อคิว</span>',
        'pause': '<span class="rounded px-1 badge-danger">หยุดรับชั่วคราว</span>'
      }[type]

      let note = ''
      if(type === 'pause') {
        const noteText = this.data?.recording?.note || ''
        note = ` <span class="text-secondary">(${noteText})</span>`
      }

      return `${typeText} ${note}`
    }
  },
  methods: {
    editSetting() {
      this.isShowSettingModal = true
    },
    modalClosed(needReload) {
      this.isShowSettingModal = false
      if(needReload) {
        this.$emit('reload')
      }
    },
    setStatusStyle(css) {
      this.cssStatusClass = {
        ...this.cssStatusClass,
        ...css
      }
    },
    cancelRound() {
      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      Swal.fire({
        title: 'ยกเลิกงวดหวย!',
        text: `[${this.groupName}] ${this.marketTitle} งวดวันที่ ${this.roundDate}`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'กลับ',
        reverseButtons: false,
        buttonsStyling: true,
        showCloseButton: true,
        // customClass: {
        //   actions: 'd-flex justify-content-around pt-3',
        //   confirmButton: 'btn btn-danger',
        //   cancelButton: 'btn btn-outline-secondary',
        //   validationMessage: 'alert alert-danger border-danger m-2'
        // },
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.cancelRound(this.data._id, uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.showValidationMessage(
              `ผิดพลาด: ${error.message}`
            )
          })
        },
      })
      .then((res)=>{
        if(res.isConfirmed) {
          console.log(res)
          Swal.fire(
            'สำเร็จ!',
            'ยกเลิกงวดหวยเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.swal2-validation-message {
  background: #f8d7da;
  color: #842029;
}
</style>
